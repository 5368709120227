import request from '@/utils/request-im'

export function queryArticle(parameter) {

  return request({
    // url: 'http://tim.rashsjfw.com/api/im/article/page',
    url: '/api/im/article/page',
    method: 'post',
    data: parameter
  })
}

export function updateArticle(parameter) {
  return request({
    // url: 'http://tim.rashsjfw.com/api/im/article/add',
    url: '/api/im/article/add',
    method: 'post',
    data: parameter
  })
}

export function deleteArticle(parameter) {
  return request({
    // url: 'http://tim.rashsjfw.com/api/im/article/delete/' + parameter.id,
    url: '/api/im/article/delete/' + parameter.id,
    method: 'delete',
  })
}
