<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew({})'>新增</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData' :showPagination="false">
        <span slot='fileUrl' slot-scope='text, record'>
          <template>
<!--            <img :src='record.' width='180px' :preview="record.id" >-->
            <a :href="record.fileUrl" target="_blank">{{ text }}</a>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='handleNew(record)'>编辑</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>

        <span slot='permission' slot-scope='text, record'>
          <template>
            {{getPermissionStr(text)}}
          </template>
        </span>
      </s-table>
    </div>
    <a-modal title='编辑文件' :destroyOnClose='true' :maskClosable='true' :visible='deliver.visible'
             @cancel='()=>this.deliver.visible=false'
             ok-text='提交' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading'>
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='文件：' prop='fileUrl'>
          <a-upload
            list-type='picture-card'
            :file-list="deliver.form.fileUrl ? [{
              uid: '-1',
              name: 'fileUrl',
              status: 'done',
              url: deliver.form.fileUrl,
            }] : []"
            :customRequest='(event) => {handleUpload(event, "fileUrl")}'
            :remove='() => deliver.form.fileUrl = ""'>
            <div v-if="!deliver.form.fileUrl">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传文件</div>
            </div>
          </a-upload>
          <div v-if="deliver.form.fileUrl">
            {{this.deliver.form.fileName}}
          </div>
        </a-form-model-item>


        <a-form-model-item label='颁发部门' prop='department' >
          <a-input v-model='deliver.form.department' />
        </a-form-model-item>

        <a-form-model-item label="签发日期" prop='publishDate'>
            <a-date-picker :valueFormat="createFormatType" v-model="deliver.form.publishDate" placeholder="选择日期"/>
        </a-form-model-item>

        <a-form-model-item label='提示内容' prop='showAround'>
          <a-radio-group name="showAround" v-model="deliver.form.showAround"  @change="changeValue">
            <a-radio :value="'true'">
              公开（全部可见）
            </a-radio>
            <a-radio :value="'false'">
              部分可见
            </a-radio>

          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label=' ' :colon="false" prop='permission' v-if="deliver.form.showAround==='false'" >
          <a-checkbox-group v-model='deliver.form.permissionArr' @change="onChangeCheck">
            <a-checkbox :value='1' >粉丝</a-checkbox>
            <a-checkbox :value='2'>会员</a-checkbox>
            <a-checkbox :value='4'>备选服务</a-checkbox>
            <a-checkbox :value='8'>服务中心</a-checkbox>
            <a-checkbox :value='16'>备选运营</a-checkbox>
            <a-checkbox :value='32'>运营中心</a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {queryArticle, deleteArticle, updateArticle,} from '@/api/im'
import client from '@/config/oss.config.js'

const columns = [
  {
    width: 150,
    title: '文件',
    dataIndex: 'fileName',
    scopedSlots: { customRender: 'fileUrl' }
  },
  {
    width: 150,
    title: '颁发部门',
    dataIndex: 'department'
  },
  {
    width: 150,
    title: '签发日期',
    dataIndex: 'publishDate'
  },
  {
    width: 200,
    title: '可见范围',
    dataIndex: 'permission',
    scopedSlots: { customRender: 'permission' }
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
const createFormatType = 'YYYY-MM-DD';
export default {
  name: 'Account',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      loading: false,
      // 表头
      columns,
      createFormatType,
      payChannelList: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter);

        return queryArticle(params).then(datum => {

            this.$previewRefresh()
            return {
              records: datum.records
            }
          })
      },
      deliver: { 
        loading: false,
        visible: false, 
        form: {},
        rules: {
          fileUrl: [{ required: true, message: '请上传文件', trigger: 'change' }],
          department: [{ required: true, message: '请填写颁发部门', trigger: 'change' }],
          publishDate: [{ required: true, message: '请填写签发日期', trigger: 'change' }],
          showAround: [{ required: true, message: '请选择可见范围', trigger: 'change' }],
        }
      },
    }
  },
  created() {

    this.handleInitial()
  },
  methods: {
    async handleUpload(event, key) {
      this.loading = true
      let result = await client.put('article/' + event.file.uid, event.file)
      this.$set(this.deliver.form, key, result.url)
      this.$set(this.deliver.form, "fileName", event.file.name)
      this.loading = false
      this.$refs.form.clearValidate()
    },
    handleNew(record) {
      console.log("&&&&&&&&&&&&&&&&&&&&&&&")
      debugger;
      if(record.id){
        this.deliver.form = record;
        if(record.permission=="-1"){
          this.$set(this.deliver.form, "showAround", "true")
        }else{
          let perList = [1,2,4,8,16,32];
          let permissionList = [];
          for(let item of perList){
            let res = record.permission & item;
            if(res >0){
              permissionList.push(res);
            }
          }
          console.log(permissionList)

          this.$set(this.deliver.form, "showAround", "false");
          this.$set(this.deliver.form, "permissionArr", permissionList);
        }
      }else{
        this.deliver.form = {};
      }
      this.deliver.visible = true;

    },
    handleDeliver() {
      this.$refs.form.validate(result => {
       if (result) {
        this.deliver.loading = true;
        if(this.deliver.form.showAround == "true"){
          this.deliver.form.permission = "-1";
        }
        let params = Object.assign(this.deliver.form);
        // debugger;
         updateArticle(params).then(() => {
          this.$message.success('操作成功')
          this.deliver.visible = false;
          this.$refs.table.refresh(true)
          this.deliver.loading = false;
        }).catch(() => {
          this.deliver.loading = false;
        })
       }
      })
    },
    handleDelete(record) {
      deleteArticle({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    } ,
    handleInitial() {
      // this.handleChannelNameSearch('');
    },

    changeValue(e) {
      let temp=16&16;
      console.log(temp)
    },
    onChangeCheck(e){
      let permission = 0;
      if(e && e.length>0){
        for( let i of e){
          permission = permission + i;
        }
      }
      this.deliver.form.permission = permission;
    },
    getPermissionStr (permission){
      if(permission == -1){
        return "全部";
      }else{
        let arr = [];
        arr[1] = '粉丝';
        arr[2] = '会员';
        arr[4] = '备选服务';
        arr[8] = '服务中心';
        arr[16] = '备选运营';
        arr[32] = '运营中心';

        let permissionList = [];
        let perList = [1,2,4,8,16,32];
        let str = "";
        for(let item of perList){
          let res = permission & item;
          if(res >0){
            permissionList.push(res);
          }
        }
        for(let item of permissionList){
          str =  str+ arr[item] +",";
        }
        return str;
      }
    },

  }
}
</script>
